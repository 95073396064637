import React from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import CheckoutForm from "./CheckoutForm"

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY)

export default function StripeElement(props) {
  const { clientSecret, cancelPayment } = props
  const options = {
    clientSecret,
    appearance: {
      theme: "stripe",
      labels: "floating",
      colorWarningText: "red",
    },
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm cancelPayment={cancelPayment} />
    </Elements>
  )
}
