import React from "react"
import { Grid, Button } from "@material-ui/core"
import { LoadingButton } from "@material-ui/lab"
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { useState } from "react"

export default function CheckoutForm({ cancelPayment }) {
  const stripe = useStripe()
  const elements = useElements()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const handleSubmit = async event => {
    setIsSubmitting(true)
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.FO_URL}/publie-annonce#payment=ok`,
      },
    })

    if (error) {
      setErrorMessage(error.message)
    }
    setIsSubmitting(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {stripe && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Button
              fullWidth
              size="large"
              type="button"
              variant="contained"
              color="error"
              sx={{ my: 2, backgroundColor: "rgb(255, 72, 66)" }}
              className="ValiderForm bouton_payer"
              disabled={isSubmitting}
              onClick={cancelPayment}
              style={{ backgroundColor: "rgb(255, 72, 66) !important" }}
            >
              Annuler
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ my: 2 }}
              className="ValiderForm bouton_payer"
              style={{ backgroundColor: "red" }}
            >
              Payer
            </Button>
          </Grid>
        </Grid>
      )}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  )
}
