import React, { useState, useEffect } from "react"
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { Form, FormikProvider, useFormik } from "formik"
import { Link } from "gatsby"
import * as Yup from "yup"
import axios from "axios"
import numeral from "numeral"
import LoadingOverlay from "react-loading-overlay"
import CustomUploadLogo from "../CreerCompte/UploadLogo/UploadLogo"
import StripeElement from "../stripe/StripeElement"
import ResultaPop from "./ResultPop/ResultPop"
import "./PublierAnnonce.css"

const moment = require("moment")

function PublierAnnonce({
  regions,
  cities,
  types,
  sectors,
  levels,
  publicAdmis,
  mobilizableDevices,
  degrees,
  options,
  article_prices,
}) {
  const [filteredCities, setFilteredCities] = useState(cities)
  const [featuredChecked, setFeaturedChecked] = useState(0)
  const [sponsoredChecked, setSponsoredChecked] = useState(0)
  const [agencyName, setAgencyName] = useState("")
  const [regionId, setRegionId] = useState(null)
  const [image, setImage] = useState()
  const [fileSubmit, setFileSubmit] = useState(false)
  const [isResult, setResult] = useState(false)
  const [priceArticle, setArticlePrice] = useState(0)
  const [logos, setLogos] = useState([])

  const [order, setOrder] = useState({
    article_id: null,
    payment_token: null,
    order_summary: {
      publication: 0,
      featured: 0,
      sponsored: 0,
      tva_rate: 0,
      tva_amount: 0,
    },
  })
  const featured = (options || []).filter(
    option => option.type === "news" && option
  )
  const sponsored = (options || []).filter(
    option => option.type === "sponsorised" && option
  )
  const trainingArticleValidation = Yup.object().shape({
    city_id: Yup.number()
      .typeError("Ce champs est requis.")
      .required("Ce champs est requis."),
    region_id: Yup.number()
      .typeError("Ce champs est requis.")
      .required("Ce champs est requis."),
    goals: Yup.string().required("Ce champs est requis."),
    start_date: Yup.date()
      .min(
        moment().subtract(1, "days").hour(23).minute(59).second(59),
        "Doit être supérieure à la date d'aujourdui"
      )
      .required("Ce champs est requis."),
    end_date: Yup.date()
      .required("Ce champs est requis.")
      .when("start_date", (start_date, schema) =>
        schema.min(start_date, "Doit être supérieure à la date de début")
      ),
    training_hours: Yup.number()
      .typeError("Veuillez saisir un nombre.")
      .positive("Veuillez saisir un nombre positif.")
      .nullable(),
    training_days: Yup.number()
      .typeError("Veuillez saisir un chiffre.")
      .positive("Veuillez saisir un nombre positif.")
      .nullable(),
    training_months: Yup.number()
      .typeError("Veuillez saisir un chiffre.")
      .positive("Veuillez saisir un nombre positif.")
      .nullable(),
  })

  const articleValidation = Yup.object().shape({
    title: Yup.string().required("Ce champs est requis."),
    agency_name: Yup.string().required("Ce champs est requis."),
    contact_recrutment_email: Yup.string()
      .email("Adresse e-mail non valide")
      .required("Ce champs est requis."),
    type_id: Yup.number()
      .typeError("Ce champs est requis.")
      .required("Ce champs est requis."),
    sector: Yup.number()
      .typeError("Ce champs est requis.")
      .required("Ce champs est requis."),
    level_id: Yup.number()
      .typeError("Ce champs est requis.")
      .required("Ce champs est requis."),
    training_article: trainingArticleValidation,
  })

  const formSchemaValidation = Yup.object().shape({
    articleToAdd: articleValidation,
  })

  const formik = useFormik({
    initialValues: {
      articleToAdd: {
        title: "",
        contact_recrutment_email: "",
        agency_name: "",
        level_id: "",
        mobilization_device_id: "",
        public_admis_id: null,
        sector: "",
        type_id: "",
        training_article: {
          city_id: "",
          region_id: "",
          degree_obtained: "",
          start_date: moment().format("YYYY-MM-DD"),
          end_date: moment().endOf("month").format("YYYY-MM-DD"),
          goals: "",
          job_targeted: "",
          programm: "",
          success_rate: 0,
          training_hours: "",
          training_days: "",
          training_months: "",
        },
        article_option: {
          option_une_id: "",
          option_sponsored_id: "",
        },
      },
      payBy: {
        adresss_facturation: {},
        agency_name_facturation: "",
        user_create: null,
        payment_mode: "paynow",
      },
      subscriptionAccount: {
        subscription_account_id: null,
        annonces_left: null,
        subscription_id: null,
      },
      agency: {
        name: "",
        email: "",
        logo: null,
      },
    },
    validationSchema: formSchemaValidation,
    enableReinitialze: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)

      const publicAdmisId = {}
      if (values.articleToAdd.public_admis_id) {
        publicAdmisId.public_admis_id = parseInt(
          values.articleToAdd.public_admis_id,
          10
        )
      }

      const degreeObtained = {}
      if (values.public_admis_id) {
        degreeObtained.degree_obtained = parseInt(
          values.articleToAdd.training_article.degree_obtained,
          10
        )
      }

      const article_agency = {
        name: values.articleToAdd.agency_name,
        email: values.articleToAdd.contact_recrutment_email,
        logo: logos.map(logo => logo.src),
      }

      delete values.articleToAdd.agency_name
      delete values.articleToAdd.training_article.region_id
      delete values.articleToAdd.training_article.degree_obtained

      const dataAnnonce = {
        ...values,
        agency: article_agency,
        articleToAdd: {
          ...values.articleToAdd,
          mobilization_device_id: parseInt(
            values.articleToAdd.mobilization_device_id,
            10
          ),
          ...publicAdmisId,
          level_id: parseInt(values.articleToAdd.level_id, 10),
          type_id: parseInt(values.articleToAdd.type_id, 10),
          sector: parseInt(values.articleToAdd.sector, 10),
          article_option: {
            ...values.articleToAdd.article_option,
            option_sponsored_id: parseInt(
              values.articleToAdd.article_option.option_sponsored_id,
              10
            ),
            option_une_id: parseInt(
              values.articleToAdd.article_option.option_une_id,
              10
            ),
          },
          training_article: {
            ...values.articleToAdd.training_article,
            city_id: parseInt(values.articleToAdd.training_article.city_id, 10),
            ...degreeObtained,
            start_date:
              (values.articleToAdd.training_article.start_date &&
                moment(
                  values.articleToAdd.training_article.start_date,
                  "YYYY-MM-DD"
                ).format("DD/MM/YYYY")) ||
              "",
            end_date:
              (values.articleToAdd.training_article.end_date &&
                moment(
                  values.articleToAdd.training_article.end_date,
                  "YYYY-MM-DD"
                ).format("DD/MM/YYYY")) ||
              "",
            training_hours: parseInt(
              values.articleToAdd.training_article.training_hours,
              10
            ),
            training_days: parseInt(
              values.articleToAdd.training_article.training_days,
              10
            ),
            training_months: parseInt(
              values.articleToAdd.training_article.training_months,
              10
            ),
            success_rate: parseInt(
              values.articleToAdd.training_article.success_rate,
              10
            ),
          },
        },
      }

      const { data } = await axios.post("/.netlify/functions/create-annonce", {
        ...dataAnnonce,
      })

      setOrder(
        (data.data && data.data.AddArticle) || {
          article_id: null,
          payment_token: null,
          order_summary: {
            publication: 0,
            featured: 0,
            sponsored: 0,
            tva_rate: 0,
            tva_amount: 0,
          },
        }
      )

      setSubmitting(false)
    },
  })
  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik

  useEffect(() => {
    if (document.location.hash === "#payment=ok") {
      setResult(true)
    }
  }, [])

  useEffect(() => {
    setArticlePrice((article_prices && article_prices[0].price) || 0)
  }, [article_prices])

  const formatDuration = duration => {
    const digits = duration.match(/\d+/g)
    const letters = duration.match(/[a-zA-Z]+/g)
    const _return = (letters && letters.length && letters[0]) || ""
    let durationUnit = "heures"
    switch (_return) {
      case "w":
        durationUnit = "semaine(s)"
        break
      case "m":
        durationUnit = "mois"
        break
      case "d":
        durationUnit = "jours"
        break
      default:
        durationUnit = "heures"
    }
    return `${parseInt(digits[0], 10)} ${durationUnit}`
  }

  const fCurrency = number => {
    return `${numeral(number).format(
      Number.isInteger(number) ? "0,0" : "0,0.00"
    )} €`
  }

  const handleChangeRegion = e => {
    setFilteredCities(
      (e.target.value &&
        cities.filter(
          item => parseInt(item.region.id, 10) === parseInt(e.target.value, 10)
        )) ||
        cities
    )
    setFieldValue("articleToAdd.training_article.region_id", e.target.value)
  }

  const handleChangeFeatured = event => {
    setFeaturedChecked(event.target.value)
    setFieldValue(
      "articleToAdd.article_option.option_une_id",
      event.target.value
    )
  }

  const handleChangeSponsored = event => {
    setSponsoredChecked(event.target.value)
    setFieldValue(
      "articleToAdd.article_option.option_sponsored_id",
      event.target.value
    )
  }

  const onRemoveLogo = index => {
    logos.splice(index, 1)
    setLogos([...logos])
  }

  const onUpload = files => {
    const file = files[0]
    if (!file) return
    if (logos.length >= 3) return
    setImage(file)

    setFileSubmit(true)

    // reader
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = e => reject(e)
    })
      .then(async base64 => {
        const resFile = await axios.post("/.netlify/functions/upload-file", {
          buffer: base64,
          name: file.name,
          type: file.type,
        })

        setImage({
          src: resFile.data.filepath,
          image_id: resFile.data.media_id,
        })
        logos.push({
          src: resFile.data.filepath,
          image_id: resFile.data.media_id,
        })
        setLogos([...logos])

        setFileSubmit(false)
      })
      .catch(e => {
        console.error(e)
        setFileSubmit(false)
      })
  }

  return (
    <>
      {(isResult && <ResultaPop />) || (
        <LoadingOverlay
          active={isSubmitting || fileSubmit}
          spinner
          text={fileSubmit ? "Chargement fichier ..." : "Envoi..."}
        >
          <div className="container">
            <div className="row">
              <div className="content_page_publie_annonce_inner">
                <FormikProvider value={formik}>
                  {(!order.payment_token && (
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                      <h2 className="title-section text-center">
                        Publier <span>une annonce</span>
                      </h2>
                      <h3 className="titre3_section">
                        Information <span>sur la structure</span>
                      </h3>
                      <div className="content_interne">
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-xs-12">
                            <fieldset>
                              <label className="label-form">
                                Nom du centre de formation / Nom de l'entreprise
                                *
                              </label>
                              <TextField
                                {...getFieldProps("articleToAdd.agency_name")}
                                fullWidth
                                error={Boolean(
                                  touched.articleToAdd?.agency_name &&
                                    errors.articleToAdd?.agency_name
                                )}
                                helperText={
                                  touched.articleToAdd?.agency_name &&
                                  errors.articleToAdd?.agency_name
                                }
                                disabled={isSubmitting}
                                required
                              />
                            </fieldset>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-lg-6 col-xs-12">
                            <fieldset>
                              <label className="label-form">
                                Contact principal *
                              </label>
                              <TextField
                                {...getFieldProps(
                                  "articleToAdd.contact_recrutment_email"
                                )}
                                fullWidth
                                error={Boolean(
                                  touched.articleToAdd
                                    ?.contact_recrutment_email &&
                                    errors.articleToAdd
                                      ?.contact_recrutment_email
                                )}
                                helperText={
                                  touched.articleToAdd
                                    ?.contact_recrutment_email &&
                                  errors.articleToAdd?.contact_recrutment_email
                                }
                                disabled={isSubmitting}
                                required
                              />
                            </fieldset>
                          </div>
                          <div className="col-md-6 col-lg-6 col-xs-12">
                            <fieldset>
                              <label className="label-form">
                                Logo principal *
                              </label>
                              <CustomUploadLogo
                                placeholder="Cliquer pour sélectionner le fichier"
                                maxSize={5000000}
                                accept={{
                                  "image/*": [".png", ".jpeg", ".jpg"],
                                }}
                                onDrop={onUpload}
                                {...getFieldProps("entreprise_logo")}
                                value={image?.src || values.entreprise_logo}
                                logos={logos}
                                onRemove={onRemoveLogo}
                                error={Boolean(
                                  touched.entreprise_logo &&
                                    errors.entreprise_logo
                                )}
                                helperText={
                                  touched.entreprise_logo &&
                                  errors.entreprise_logo
                                }
                              />
                            </fieldset>
                          </div>
                        </div>
                      </div>
                      <h3 className="titre3_section">
                        Publier <span>votre annonce</span>
                      </h3>
                      <h4 className="titre4_section">
                        Informations principales
                      </h4>
                      <div className="content_interne">
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-xs-12">
                            <fieldset>
                              <label className="label-form">
                                Titre de l'annonce *
                              </label>
                              <TextField
                                {...getFieldProps("articleToAdd.title")}
                                fullWidth
                                error={Boolean(
                                  touched.articleToAdd?.title &&
                                    errors.articleToAdd?.title
                                )}
                                helperText={
                                  touched.articleToAdd?.title &&
                                  errors.articleToAdd?.title
                                }
                                disabled={isSubmitting}
                                required
                              />
                            </fieldset>
                            <fieldset>
                              <label className="label-form">Région *</label>
                              <TextField
                                {...getFieldProps(
                                  "articleToAdd.training_article.region_id"
                                )}
                                className="custom-select"
                                select
                                SelectProps={{ native: true }}
                                onChange={handleChangeRegion}
                                error={Boolean(
                                  touched.articleToAdd?.training_article
                                    ?.region_id &&
                                    errors.articleToAdd?.training_article
                                      ?.region_id
                                )}
                                helperText={
                                  touched.articleToAdd?.training_article
                                    ?.region_id &&
                                  errors.articleToAdd?.training_article
                                    ?.region_id
                                }
                                disabled={isSubmitting}
                                required
                              >
                                <option value="" />
                                {(regions || []).map(option => (
                                  <option key={option.id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </TextField>
                            </fieldset>
                            <fieldset>
                              <label className="label-form">Ville *</label>
                              <TextField
                                {...getFieldProps(
                                  "articleToAdd.training_article.city_id"
                                )}
                                className="custom-select"
                                select
                                SelectProps={{ native: true }}
                                error={Boolean(
                                  touched.articleToAdd?.training_article
                                    ?.city_id &&
                                    errors.articleToAdd?.training_article
                                      ?.city_id
                                )}
                                helperText={
                                  touched.articleToAdd?.training_article
                                    ?.city_id &&
                                  errors.articleToAdd?.training_article?.city_id
                                }
                                disabled={isSubmitting}
                                required
                              >
                                <option value="" />
                                {(filteredCities || []).map((option, key) => (
                                  <option key={key} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </TextField>
                            </fieldset>
                            <fieldset>
                              <label className="label-form">
                                Type de formation *
                              </label>
                              <TextField
                                {...getFieldProps("articleToAdd.type_id")}
                                className="custom-select"
                                select
                                SelectProps={{ native: true }}
                                error={Boolean(
                                  touched.articleToAdd?.type_id &&
                                    errors.articleToAdd?.type_id
                                )}
                                helperText={
                                  touched.articleToAdd?.type_id &&
                                  errors.articleToAdd?.type_id
                                }
                                disabled={isSubmitting}
                                required
                              >
                                <option value="" />
                                {(types || []).map(option => (
                                  <option key={option.id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </TextField>
                            </fieldset>
                            <fieldset>
                              <label className="label-form">
                                Secteur d'activité *
                              </label>
                              <TextField
                                {...getFieldProps("articleToAdd.sector")}
                                className="custom-select"
                                select
                                SelectProps={{ native: true }}
                                error={Boolean(
                                  touched.articleToAdd?.sector &&
                                    errors.articleToAdd?.sector
                                )}
                                helperText={
                                  touched.articleToAdd?.sector &&
                                  errors.articleToAdd?.sector
                                }
                                disabled={isSubmitting}
                                required
                              >
                                <option value="" />
                                {(sectors || []).map(option => (
                                  <option key={option.id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </TextField>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                      <h4 className="titre4_section">
                        Descriptions de l'annonce
                      </h4>
                      <div className="content_interne">
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-xs-12">
                            <fieldset>
                              <label className="label-form">
                                Objectifs et Programme *
                              </label>
                              <TextField
                                {...getFieldProps(
                                  "articleToAdd.training_article.goals"
                                )}
                                multiline
                                minRows={5}
                                error={Boolean(
                                  touched.articleToAdd?.training_article
                                    ?.goals &&
                                    errors.articleToAdd?.training_article?.goals
                                )}
                                helperText={
                                  touched.articleToAdd?.training_article
                                    ?.goals &&
                                  errors.articleToAdd?.training_article?.goals
                                }
                                disabled={isSubmitting}
                                required
                              />
                            </fieldset>
                            <fieldset>
                              <label className="label-form">
                                Métiers visés
                              </label>
                              <TextField
                                {...getFieldProps(
                                  "articleToAdd.training_article.job_targeted"
                                )}
                                multiline
                                minRows={5}
                                error={Boolean(
                                  touched.articleToAdd?.training_article
                                    ?.job_targeted &&
                                    errors.articleToAdd?.training_article
                                      ?.job_targeted
                                )}
                                helperText={
                                  touched.articleToAdd?.training_article
                                    ?.job_targeted &&
                                  errors.articleToAdd?.training_article
                                    ?.job_targeted
                                }
                                disabled={isSubmitting}
                              />
                            </fieldset>
                          </div>
                        </div>
                      </div>
                      <h4 className="titre4_section">
                        Détails de la formation
                      </h4>
                      <div className="content_interne">
                        <div className="row">
                          <div className="col-md-6 col-lg-6 col-xs-12">
                            <fieldset>
                              <label className="label-form">
                                Date de début
                              </label>
                              <TextField
                                className="inputDate"
                                {...getFieldProps(
                                  "articleToAdd.training_article.start_date"
                                )}
                                type="date"
                                error={Boolean(
                                  touched.articleToAdd?.training_article
                                    ?.start_date &&
                                    errors.articleToAdd?.training_article
                                      ?.start_date
                                )}
                                helperText={
                                  touched.articleToAdd?.training_article
                                    ?.start_date &&
                                  errors.articleToAdd?.training_article
                                    ?.start_date
                                }
                                disabled={isSubmitting}
                                required
                              />
                            </fieldset>
                          </div>
                          <div className="col-md-6 col-lg-6 col-xs-12">
                            <fieldset>
                              <label className="label-form">Date de fin</label>
                              <TextField
                                className="inputDate"
                                {...getFieldProps(
                                  "articleToAdd.training_article.end_date"
                                )}
                                type="date"
                                error={Boolean(
                                  touched.articleToAdd?.training_article
                                    ?.end_date &&
                                    errors.articleToAdd?.training_article
                                      ?.end_date
                                )}
                                helperText={
                                  touched.articleToAdd?.training_article
                                    ?.end_date &&
                                  errors.articleToAdd?.training_article
                                    ?.end_date
                                }
                                disabled={isSubmitting}
                                required
                              />
                            </fieldset>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4 col-lg-4 col-xs-12">
                            <fieldset>
                              <label className="label-form">
                                Durée de la formation
                              </label>
                              <TextField
                                {...getFieldProps(
                                  "articleToAdd.training_article.training_hours"
                                )}
                                placeholder="Heure"
                                error={Boolean(
                                  touched.articleToAdd?.training_article
                                    ?.training_hours &&
                                    errors.articleToAdd?.training_article
                                      ?.training_hours
                                )}
                                helperText={
                                  touched.articleToAdd?.training_article
                                    ?.training_hours &&
                                  errors.articleToAdd?.training_article
                                    ?.training_hours
                                }
                                disabled={isSubmitting}
                              />
                            </fieldset>
                          </div>
                          <div className="col-md-4 col-lg-4 col-xs-12">
                            <fieldset>
                              <label className="label-form">&nbsp;</label>
                              <TextField
                                {...getFieldProps(
                                  "articleToAdd.training_article.training_days"
                                )}
                                placeholder="Jours"
                                error={Boolean(
                                  touched.articleToAdd?.training_article
                                    ?.training_days &&
                                    errors.articleToAdd?.training_article
                                      ?.training_days
                                )}
                                helperText={
                                  touched.articleToAdd?.training_article
                                    ?.training_days &&
                                  errors.articleToAdd?.training_article
                                    ?.training_days
                                }
                                disabled={isSubmitting}
                              />
                            </fieldset>
                          </div>
                          <div className="col-md-4 col-lg-4 col-xs-12">
                            <fieldset>
                              <label className="label-form">&nbsp;</label>
                              <TextField
                                {...getFieldProps(
                                  "articleToAdd.training_article.training_months"
                                )}
                                placeholder="Mois"
                                error={Boolean(
                                  touched.articleToAdd?.training_article
                                    ?.training_months &&
                                    errors.articleToAdd?.training_article
                                      ?.training_months
                                )}
                                helperText={
                                  touched.articleToAdd?.training_article
                                    ?.training_months &&
                                  errors.articleToAdd?.training_article
                                    ?.training_months
                                }
                                disabled={isSubmitting}
                              />
                            </fieldset>
                          </div>
                        </div>
                      </div>
                      <h4 className="titre4_section">
                        Critères de recherche du candidat
                      </h4>
                      <div className="content_interne">
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-xs-12">
                            <fieldset>
                              <label className="label-form">
                                Niveau d'entrée *
                              </label>
                              <TextField
                                {...getFieldProps("articleToAdd.level_id")}
                                className="custom-select"
                                select
                                SelectProps={{ native: true }}
                                error={Boolean(
                                  touched.articleToAdd?.level_id &&
                                    errors.articleToAdd?.level_id
                                )}
                                helperText={
                                  touched.articleToAdd?.level_id &&
                                  errors.articleToAdd?.level_id
                                }
                                disabled={isSubmitting}
                                required
                              >
                                <option value="" />
                                {(levels || []).map(option => (
                                  <option key={option.id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </TextField>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                      <h4 className="titre4_section">Options</h4>
                      <div className="content_interne">
                        <div className="titre-text-option">
                          <span className="text-mise-en-avant">
                            Mise en avant de votre offre de formation *
                          </span>
                        </div>
                        <RadioGroup>
                          <div className="row">
                            <div className="col-md-4 col-lg-4 col-xs-12">
                              <fieldset>
                                <FormControlLabel
                                  {...getFieldProps(
                                    "articleToAdd.article_option.option_une_id"
                                  )}
                                  control={<Radio />}
                                  value={0}
                                  label="Aucune"
                                  checked={parseInt(featuredChecked, 10) === 0}
                                  onChange={handleChangeFeatured}
                                />
                              </fieldset>
                            </div>
                            {(featured || []).map(item => (
                              <div
                                key={item.option_id}
                                className="col-md-4 col-lg-4 col-xs-12"
                              >
                                <fieldset>
                                  <FormControlLabel
                                    {...getFieldProps(
                                      "articleToAdd.article_option.option_une_id"
                                    )}
                                    control={<Radio />}
                                    label={`${formatDuration(
                                      item?.duration
                                    )}, ${item.price}€ HT`}
                                    value={item.option_id}
                                    checked={
                                      parseInt(featuredChecked, 10) ===
                                      parseInt(item.option_id, 10)
                                    }
                                    onChange={handleChangeFeatured}
                                  />
                                </fieldset>
                              </div>
                            ))}
                          </div>
                        </RadioGroup>
                        <div className="titre-text-option">
                          <span className="text-mise-en-avant">
                            Option Annonces Sponsorisées *
                          </span>
                        </div>
                        <RadioGroup>
                          <div className="row">
                            <div className="col-md-4 col-lg-4 col-xs-12">
                              <fieldset>
                                <FormControlLabel
                                  {...getFieldProps(
                                    "articleToAdd.article_option.option_sponsored_id"
                                  )}
                                  control={<Radio />}
                                  label="Aucune"
                                  value={0}
                                  checked={parseInt(sponsoredChecked, 10) === 0}
                                  onChange={handleChangeSponsored}
                                />
                              </fieldset>
                            </div>
                            {(sponsored || []).map(item => (
                              <div
                                key={item.option_id}
                                className="col-md-4 col-lg-4 col-xs-12"
                              >
                                <fieldset>
                                  <FormControlLabel
                                    {...getFieldProps(
                                      "articleToAdd.article_option.option_sponsored_id"
                                    )}
                                    control={<Radio />}
                                    label={`${formatDuration(item.duration)}, ${
                                      item.price
                                    }€ HT`}
                                    value={item.option_id}
                                    checked={
                                      parseInt(sponsoredChecked, 10) ===
                                      parseInt(item.option_id, 10)
                                    }
                                    onChange={handleChangeSponsored}
                                  />
                                </fieldset>
                              </div>
                            ))}
                          </div>
                        </RadioGroup>
                      </div>

                      <div className="content_bouton_paiement">
                        <span className="text_paiement">
                          Payer l'annonce {priceArticle} €
                        </span>
                        <Button className="bouton_payer" type="submit">
                          Payer et Publier
                        </Button>
                      </div>
                    </Form>
                  )) || (
                    <>
                      <h4 className="titre4_section">Paiement</h4>
                      <Grid
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          justifyContent: "space-between",
                          padding: "15px 25px",
                          alignItems: "center",
                          margin: "0",
                          width: "100%",
                        }}
                        container
                        spacing={3}
                      >
                        <Box
                          style={{
                            width: "50%",
                            paddingLeft: 15,
                            paddingRight: 15,
                          }}
                        >
                          <>
                            <TableContainer>
                              <Table>
                                <TableHead
                                  sx={{
                                    borderBottom: theme =>
                                      `solid 1px ${theme.palette.divider}`,
                                    "& th": { backgroundColor: "transparent" },
                                  }}
                                >
                                  <TableRow>
                                    <TableCell align="left">
                                      Description
                                    </TableCell>
                                    <TableCell align="right">Montant</TableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {order.order_summary?.publication > 0 && (
                                    <TableRow
                                      key={0}
                                      sx={{
                                        borderBottom: theme =>
                                          `solid 1px ${theme.palette.divider}`,
                                      }}
                                    >
                                      <TableCell align="left">
                                        <Box>
                                          <Typography variant="subtitle2">
                                            Publication d'annonce
                                          </Typography>
                                        </Box>
                                      </TableCell>
                                      <TableCell align="right">
                                        {fCurrency(
                                          order.order_summary?.publication
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  )}

                                  {order.order_summary?.featured > 0 && (
                                    <TableRow
                                      key={1}
                                      sx={{
                                        borderBottom: theme =>
                                          `solid 1px ${theme.palette.divider}`,
                                      }}
                                    >
                                      <TableCell align="left">
                                        <Box>
                                          <Typography variant="subtitle2">
                                            Annonce à la une
                                          </Typography>
                                        </Box>
                                      </TableCell>
                                      <TableCell align="right">
                                        {fCurrency(
                                          order.order_summary?.featured
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  )}

                                  {order.order_summary?.sponsored > 0 && (
                                    <TableRow
                                      key={2}
                                      sx={{
                                        borderBottom: theme =>
                                          `solid 1px ${theme.palette.divider}`,
                                      }}
                                    >
                                      <TableCell align="left">
                                        <Box>
                                          <Typography variant="subtitle2">
                                            Annonce sponsorisée
                                          </Typography>
                                        </Box>
                                      </TableCell>
                                      <TableCell align="right">
                                        {fCurrency(
                                          order.order_summary?.sponsored
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  )}

                                  <TableRow>
                                    <TableCell align="right">
                                      <Typography variant="subtitle2">{`TVA (${order.order_summary?.tva_rate}%)`}</Typography>
                                    </TableCell>
                                    <TableCell align="right" width={140}>
                                      <Typography variant="subtitle2">
                                        {fCurrency(
                                          order.order_summary?.tva_amount
                                        )}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="right">
                                      <Typography variant="h6">
                                        Total
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="right" width={140}>
                                      <Typography variant="h6">
                                        {fCurrency(
                                          order.order_summary?.publication +
                                            order.order_summary?.featured +
                                            order.order_summary?.sponsored +
                                            order.order_summary?.tva_amount
                                        )}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </>
                        </Box>
                        <Box
                          style={{
                            width: "50%",
                            paddingLeft: 15,
                            paddingRight: 15,
                          }}
                        >
                          <StripeElement
                            clientSecret={order.payment_token}
                            cancelPayment={() => {
                              setOrder({
                                article_id: null,
                                payment_token: null,
                                order_summary: {
                                  publication: 0,
                                  featured: 0,
                                  sponsored: 0,
                                  tva_rate: 0,
                                  tva_amount: 0,
                                },
                              })
                              setFieldValue(
                                "articleToAdd.agency_name",
                                agencyName
                              )
                              setFieldValue(
                                "articleToAdd.training_article.region_id",
                                regionId
                              )
                            }}
                          />
                        </Box>
                      </Grid>
                    </>
                  )}
                </FormikProvider>
                <div className="contactAide">
                  <Box sx={{ flexGrow: 1 }} className="ContentTextLogin">
                    <Typography className="TextTitreContact">
                      Si vous rencontrez des problèmes sur le paiement ou la
                      publication de votre annonce,
                      <br /> merci de contacter notre Responsable Commerciale
                    </Typography>
                    <Typography>
                      <strong className="LabelFormulaireLogin">
                        Contact :{" "}
                      </strong>{" "}
                      Mme Aurélie MARIOTTI
                    </Typography>
                    <Typography>
                      <strong className="LabelFormulaireLogin">Mail : </strong>{" "}
                      aurelie.mariotti@antennereunion.fr
                    </Typography>
                    <Typography>
                      <strong className="LabelFormulaireLogin">Tel : </strong>{" "}
                      06 92 43 91 01
                    </Typography>
                    <Typography>
                      <strong className="LabelFormulaireLogin">Fax : </strong>{" "}
                      0262482817
                    </Typography>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      )}
    </>
  )
}
export default PublierAnnonce
