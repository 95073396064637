import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import { useStaticQuery, graphql } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"
import SiteMetadata from "../componets/SiteMetadata/SiteMetadata"
import Header from "../componets/Header/Header"
import Footer from "../componets/Footer/Footer"
import PublieAnnonce from "../componets/PublierAnnonce/PublierAnnonce"
import {
  translateSector,
  translateRegion,
  translateCity,
  translateLevel,
  translateType,
  translatePublicAdmis,
  translateDegree,
  translateOption,
  translateMobilizableDevice,
} from "../services/helpers"
import "./globals.css"

export const query = graphql`
  query {
    hasura {
      seformer_db_agency {
        agency_id
        name
        db_public_info_agency {
          public_info_id
          dbMediaByMainLogoId {
            link_full
          }
        }
        db_articles {
          article_id
          status
          title
        }
      }
      seformer_db_region(order_by: { name: asc }) {
        region_id
        name
        db_country {
          country_id
          name
        }
      }
      seformer_db_city(order_by: { name: asc }) {
        city_id
        name
        postal_code
        db_region {
          region_id
          name
        }
      }
      seformer_db_training_type(order_by: { name: asc }) {
        name
        type_id
      }
      seformer_db_sector(order_by: { name: asc }) {
        name
        sector_id
        db_media {
          link_full
          media_id
        }
      }
      seformer_db_level(order_by: { name: asc }) {
        level_id
        name
        image {
          link_full
          media_id
        }
      }
      seformer_db_public_admis {
        public_admis_id
        name
      }
      seformer_db_mobilization_device {
        mobilization_device_id
        name
      }
      seformer_db_degree(order_by: { name: asc }) {
        degree_id
        name
      }
      seformer_db_option(
        where: { active: { _eq: true } }
        order_by: { name: asc, price: asc }
      ) {
        option_id
        name
        type
        duration
        price
      }
      seformer_db_article_price_info(where: { is_active: { _eq: true } }) {
        price
      }
    }
    logo: file(
      absolutePath: {
        regex: "/images/logo_seformer-f59ecb37be2dc067b743d7ee0a113952.webp/"
      }
    ) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default function Home(props) {
  const location = useLocation()
  const { data } = props

  const metas = {
    title: "",
    metatitle: "",
    description: "",
    keywords: "",
  }

  const regions = data.hasura.seformer_db_region.map(region =>
    translateRegion(region)
  )
  const cities = data.hasura.seformer_db_city.map(city => translateCity(city))
  const types = data.hasura.seformer_db_training_type.map(type =>
    translateType(type)
  )
  const sectors = data.hasura.seformer_db_sector.map(sector =>
    translateSector(sector)
  )
  const levels = data.hasura.seformer_db_level.map(level =>
    translateLevel(level)
  )
  const publicAdmis = data.hasura.seformer_db_public_admis.map(admin =>
    translatePublicAdmis(admin)
  )
  const mobilizableDevices = data.hasura.seformer_db_mobilization_device.map(
    device => translateMobilizableDevice(device)
  )
  const degrees = data.hasura.seformer_db_degree.map(degree =>
    translateDegree(degree)
  )
  const options = data.hasura.seformer_db_option.map(option =>
    translateOption(option)
  )

  const article_prices = data.hasura.seformer_db_article_price_info

  return (
    <div className="content_page_publie_annonce">
      <SiteMetadata metas={metas} />
      <Header {...props} logo={data.logo} />
      <PublieAnnonce
        regions={regions}
        cities={cities}
        types={types}
        sectors={sectors}
        levels={levels}
        publicAdmis={publicAdmis}
        mobilizableDevices={mobilizableDevices}
        degrees={degrees}
        options={options}
        article_prices={article_prices}
      />
      <Footer />
    </div>
  )
}
