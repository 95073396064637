import React, { useState, useEffect } from "react"
import { useDropzone } from "react-dropzone"
import { Box, Button, Paper, InputBase, Divider } from "@material-ui/core"
import "./UploadLogo.css"

const ERROR = {
  "file-invalid-type": "Fichier PDF seulement",
  "file-too-large": `Fichier trop large 5MB max`,
}

const calcArray = data => [
  ...Array(data.length < 3 ? data.length + 1 : data.length),
]

export default function CustomUploadFile(props) {
  const { label, logos, onRemove, placeholder, error, helperText } = props

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    ...props,
    multiple: false,
  })
  const openTab = url => {
    return window.open(url, "_blank")
  }
  const handleError = message => {
    if (ERROR[message]) {
      return ERROR[message]
    }
    return "Erreur lors de l'upload du fichier"
  }
  const truncate = text => {
    return `${text.substring(0, 54)} ...`
  }
  return (
    <Box>
      <span>{label}</span>
      <div {...getRootProps({ className: "upload-root-zone" })}>
        <input {...getInputProps()} />
      </div>
      {calcArray(logos).map((v, index) => (
        <Paper
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 400,
          }}
          style={{
            width: "100%",
            cursor: "pointer",
            display: "flex",
            boxShadow: "none",
          }}
          key={index}
        >
          <div
            style={{
              width: "100%",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <input
              disabled
              sx={{ ml: 1, flex: 1 }}
              placeholder={placeholder}
              aria-label="upload-file"
              value={
                (logos && logos[index] && truncate(logos[index].src)) || ""
              }
              onChange={() => {}}
              error={`${Boolean(fileRejections.length || error)}`}
              style={{
                width: "100%",
                cursor: "pointer",
                backgroundColor: "rgba(0,0,0,0)",
                position: "relative",
                zIndex: 1,
              }}
            />
          </div>
          {logos && logos[index] && (
            <>
              <Divider sx={{ height: 50, m: 0.7 }} orientation="vertical" />
              <Button
                type="button"
                color="primary"
                onClick={() => {
                  if (logos[index]) {
                    openTab(logos[index].src)
                  }
                }}
                title={(logos[index] && "Cliquer pour voir") || ""}
              >
                Voir
              </Button>
              <Button
                type="button"
                color="primary"
                onClick={() => {
                  onRemove(index)
                }}
                title={(logos[index] && "Cliquer pour voir") || ""}
              >
                Supprimer
              </Button>
            </>
          )}
        </Paper>
      ))}
      <p
        className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1qeri3d-MuiFormHelperText-root"
        style={{
          fontSize: "0.75rem",
          textAlign: "left",
          fontWeight: 400,
          color: "#FF4842",
          marginLeft: "14px",
          marginTop: "8px",
        }}
      >
        {(fileRejections.length &&
          fileRejections.map(({ file, errors }) => {
            return errors.map(e => handleError(e.code)).join(", ")
          })) ||
          helperText ||
          ""}
      </p>
    </Box>
  )
}
